import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'avatar',
        component: () => import(/* webpackChunkName: "ComponentAvatar" */ '../views/AvatarView')
    },
    {
        path: '/poste',
        name: 'poste',
        component: () => import(/* webpackChunkName: "ComponentAvatar" */ '../components/fakeComponent')
    },
    {
        path: '/videocall/:session',
        name: 'videocall',
        // component: () => import(/* webpackChunkName: "ComponentAvatar" */ '../components/calls/RemoteVideo')
        component: () => import(/* webpackChunkName: "ComponentAvatar" */ '../components/calls/videoCallTeams')
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: "/",
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
